<template>
    <div>
        <div class="error-page">
            <!-- <div class="error-code">4<span>0</span>4</div> -->
            <div class="error-desc" v-if='isValidating'>信息验证中……</div>
            <div class="error-desc" v-else="">{{msg}}</div>
            <!-- <div class="error-handle">
                <router-link to="/">
                    <el-button type="primary" size="large">进入系统</el-button>
                </router-link>
                <el-button class="error-btn" type="primary" size="large" @click="goBack">关闭</el-button>
            </div> -->
        </div>
        <div v-show="!isValidating" class="error_end">——高擎论文评审中心</div>
    </div>
</template>

<script>
    
    export default {
        name: 'reject',
        data() {
            return {
                isValidating: true,
                msg: '',
            }
        },
        created() {
            let token = this.$route.query['token'];
            let param = new URLSearchParams();
            param.append("tokenID", token);
            this.$api.user.getReject(param)
                .then(res => {
                    if (res.data.code == 200) {
                        let inner = res.data.data;
                        this.isValidating = false;
                        this.msg = inner.message;
                    }
                }).catch(err => {
                    this.$message.warning("服务器维护");
                })
            // let param = new URLSearchParams();
            // param.append('token', token);
            // this.$axios.get("/sending_paper/reject", {
            //     params: param
            // }).then(res => {
            //     this.isValidating = false;
            //     this.msg = res.data.msg;
            //     // this.msg = this.$store.state.mapper.mailMap[res.data.code];
            // });
            
        },
        methods: {
            goBack() {

            },
            
        }
    }
</script>


<style scoped>
    .error-page {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        /* background: #f3f3f3; */
        box-sizing: border-box;
    }

    .error-code {
        line-height: 1;
        font-size: 250px;
        font-weight: bolder;
        color: #2d8cf0;
    }

    .error-code span {
        color: #00a854;
    }

    .error-desc {
        padding-top: 10px;
        font-size: 30px;
        font-family: "楷体";
        color: #777;
    }

    .error_end {
        padding-top: 10px;
        padding-right: 50px;
        font-size: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .error-handle {
        margin-top: 30px;
        padding-bottom: 200px;
    }

    .error-btn {
        margin-left: 100px;
    }
</style>

<style>
    
</style>